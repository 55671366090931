import React, { useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify';
import { useAccount, useNetwork } from 'wagmi'
import { COMPILER_VERSION, DEFAULT_CHAIN, IS_MAINNET, SUPPORTED_CHAIN, contract, contractByteCode, getWeb3, optimizationUsed, runs } from '../helper/constant';
import { ethers, ContractFactory } from 'ethers';
import tokenAbi from '../json/token.json';
import premiumAbi from '../json/premium.json';
import premiumv3Abi from '../json/premiumv3.json';
import premiumBasicAbi from '../json/basicpremium.json';
import premiumBasicv3Abi from '../json/basicpremiumv3.json';
import { useEthersSigner } from '../hooks/useEthersProvider';
import Connect from './Connect';
import { BaseContext } from '../context/BaseProvider';
import { useCommonStats } from '../hooks/useState';
import { useLocation } from 'react-router-dom';
import { api } from '../services/api';
import youtubeImg from '../images/youtube.png';
import Modal from 'react-bootstrap/Modal';
import YouTube from 'react-youtube';
import axios from 'axios';

export default function DetailsSection({ settingStats }) {
    const {
        createType,
        name,
        symbol,
        decimals,
        supply,
        burn,
        mint,
        presale,
        airdrop,
        taxFees,
        setCreated,
        buytax,
        selltax,
        mwalletaddress,
        sameOwner,
        setTokenAddress,
        setCreateHash,
        setInsertId,
        insertId
    } = useContext(BaseContext);
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner();
    const stats = useCommonStats('1');
    const [loading, setLoading] = useState(false);
    const [refAddress, setRefAddress] = useState('');
    const search = useLocation().search;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const videoId = useMemo(() => {
        return settingStats && settingStats.create_token_youtube_video_id ? settingStats.create_token_youtube_video_id : ''
    }, [settingStats]);

    useEffect(() => {
        let refAddr = '';
        const queryChainId = new URLSearchParams(search).get('ref');
        if (queryChainId !== '') {
            refAddr = queryChainId;
        }
        setRefAddress(refAddr);
        // eslint-disable-next-line
    }, [])



    const handleSubmit = async () => {
        setLoading(true);

        try {
            if (address) {
                if (SUPPORTED_CHAIN.includes(chain.id)) {
                    if (name && symbol && parseInt(decimals) > 0 && parseInt(supply) > 0) {
                        let fees;
                        let type;
                        let subtype;
                        if (createType === '0' || createType === 0) {
                            fees = stats.basicFees;
                            type = 'basic';
                            if (mint && burn) {
                                subtype = 4;
                            }
                            else if (mint) {
                                subtype = 2;
                            }
                            else if (burn) {
                                subtype = 3;
                            }
                            else {
                                subtype = 1;
                            }
                        }
                        else if (createType === '1' || createType === 1) {
                            fees = stats.standardFees;
                            type = 'standard';

                            if (mint && burn && airdrop && presale) {
                                subtype = 16;
                            }
                            else if (mint && burn && airdrop && presale) {
                                subtype = 15;
                            }
                            else if (burn && airdrop && presale) {
                                subtype = 14;
                            }
                            else if (mint && airdrop && presale) {
                                subtype = 13;
                            }
                            else if (mint && burn && presale) {
                                subtype = 12;
                            }
                            else if (airdrop && presale) {
                                subtype = 11;
                            }
                            else if (burn && airdrop) {
                                subtype = 10;
                            }
                            else if (mint && airdrop) {
                                subtype = 9;
                            }
                            else if (burn && presale) {
                                subtype = 8;
                            }
                            else if (mint && presale) {
                                subtype = 7;
                            }
                            else if (mint && burn) {
                                subtype = 6;
                            }
                            else if (airdrop) {
                                subtype = 5;
                            }
                            else if (presale) {
                                subtype = 4;
                            }
                            else if (burn) {
                                subtype = 3;
                            }
                            else if (mint) {
                                subtype = 2;
                            }
                            else {
                                subtype = 1;
                            }
                        }
                        else if (createType === '2' || createType === 2) {
                            fees = stats.premiumFees;
                            type = 'premium';
                            if (mint && burn && taxFees) {
                                subtype = 8
                            }
                            else if (burn && taxFees) {
                                subtype = 7;
                            }
                            else if (mint && taxFees) {
                                subtype = 6;
                            }
                            else if (mint && burn) {
                                subtype = 5;
                            }
                            else if (taxFees) {
                                subtype = 4;
                            }
                            else if (burn) {
                                subtype = 3;
                            }
                            else if (mint) {
                                subtype = 2;
                            }
                            else {
                                subtype = 1;
                            }
                        }
                        else {
                            setLoading(false);
                            return false;
                        }

                        if (type && subtype > 0) {
                            if (parseFloat(fees) <= parseFloat(stats.ethBalance)) {
                                let refa = refAddress && address ?
                                    refAddress.toLowerCase() === address.toLowerCase() ?
                                        '0x0000000000000000000000000000000000000000' : refAddress : '0x0000000000000000000000000000000000000000';
                                let deploycontract;
                                let factory;

                                if (createType === 2 || createType === '2') {
                                    if (subtype === 1 || subtype === 2 || subtype === 3 || subtype === 5) {

                                        factory = new ContractFactory(premiumBasicAbi, contractByteCode[type][subtype], signer);
                                        deploycontract = await factory.deploy(
                                            name,
                                            symbol,
                                            decimals,
                                            supply,
                                            contract[chain.id].MASTER_ADDRESS,
                                            type,
                                            refa,
                                            sameOwner ? address : mwalletaddress ? mwalletaddress : address,
                                            { value: ethers.utils.parseEther(fees.toString()) });

                                    }
                                    else {

                                        factory = new ContractFactory(premiumAbi, contractByteCode[type][subtype], signer);
                                        deploycontract = await factory.deploy(
                                            name,
                                            symbol,
                                            decimals,
                                            supply,
                                            contract[chain.id].MASTER_ADDRESS,
                                            type,
                                            refa,
                                            [ethers.utils.parseUnits(buytax.toString(), 2), ethers.utils.parseUnits(selltax.toString(), 2)],
                                            sameOwner ? address : mwalletaddress ? mwalletaddress : address,
                                            { value: ethers.utils.parseEther(fees.toString()) });

                                    }
                                }
                                else {
                                    factory = new ContractFactory(tokenAbi, contractByteCode[type][subtype], signer);
                                    deploycontract = await factory.deploy(
                                        name,
                                        symbol,
                                        decimals,
                                        supply,
                                        contract[chain.id].MASTER_ADDRESS,
                                        type,
                                        refa,
                                        { value: ethers.utils.parseEther(fees.toString()) });
                                }



                                if (deploycontract && deploycontract.deployTransaction) {
                                    let inserted_Id = 0;
                                    setCreateHash(deploycontract.deployTransaction.hash);
                                    setTokenAddress(deploycontract.deployTransaction.creates);
                                    let formData = new FormData();
                                    formData.append("type", type);
                                    formData.append("s_type", subtype);
                                    formData.append("name", name);
                                    formData.append("symbol", symbol);
                                    formData.append("decimals", decimals);
                                    formData.append("chain", chain.id);
                                    formData.append("request_type", 'insert_new');
                                    formData.append("refAddress", refa);
                                    formData.append("deployTransaction", JSON.stringify(deploycontract.deployTransaction));
                                    formData.append('time', new Date().getTime());
                                    formData.append('is_mainnet', IS_MAINNET ? 1 : 2);
                                    await api.post(`/`, formData).then((response) => {
                                        if (response.data && response.data.status === 'success') {
                                            setInsertId(response.data.data.id)
                                            inserted_Id = response.data.data.id;
                                        }
                                    });



                                    let web3 = getWeb3(chain && contract[chain.id] ? chain.id : DEFAULT_CHAIN);
                                    var interval = setInterval(async function () {

                                        var response = await web3.eth.getTransactionReceipt(deploycontract.deployTransaction.hash);
                                        if (response != null) {
                                            clearInterval(interval)
                                            if (response.status === true) {
                                                let c_id = chain && contract[chain.id] ? chain.id : DEFAULT_CHAIN
                                                await verifyContract(deploycontract.deployTransaction, type, subtype, c_id , inserted_Id)
                                                setCreated(true);
                                                toast.success('success ! your last transaction is success 👍');
                                                setLoading(false);

                                            }
                                            else if (response.status === false) {
                                                toast.error('error ! Your last transaction is failed.');
                                                setLoading(false);

                                            }
                                            else {
                                                toast.error('error ! something went wrong.');
                                                setLoading(false);

                                            }
                                        }
                                    }, 5000);
                                }
                                else {
                                    toast.error('Something went wrong');
                                    setLoading(false);
                                }
                            }
                            else {
                                toast.error('wallet not have enough fund for create token');
                                setLoading(false);

                            }
                        }
                        else {
                            toast.error('something went wrong.please try again later');
                            setLoading(false);
                        }
                    }
                    else {
                        toast.error('please enter valid details');
                        setLoading(false);
                    }
                }
                else {
                    toast.error('connected chain is not support. please select valid chain');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please connect wallet');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }


    const verifyContract = async (data, type, subtype, chain_id,inserted_Id, totalTry = 1) => {
        console.log(inserted_Id)
        let contract_deploy_bytecode = data.data.substring(2);
        let constructor_argument = contract_deploy_bytecode.substring(contractByteCode[type][subtype].length);
        try {
            let fileContent = await axios.get(`/contracts/${type}/${subtype}.txt`);

            console.log({
                apikey: contract[chain_id].API_KEY,
                module: 'contract',
                action: 'verifysourcecode',
                contractaddress: data.creates,
                sourceCode: fileContent.data,
                codeformat: 'solidity-single-file',
                contractname: "MAKECOINGENRATOR",
                compilerversion: COMPILER_VERSION,
                optimizationUsed: optimizationUsed,
                runs: runs,
                constructorArguements: constructor_argument
            })
            const response = await axios.post(contract[chain_id].api_endpoint, {
                apikey: contract[chain_id].API_KEY,
                module: 'contract',
                action: 'verifysourcecode',
                contractaddress: data.creates,
                sourceCode: fileContent.data,
                codeformat: 'solidity-single-file',
                contractname: "MAKECOINGENRATOR",
                compilerversion: COMPILER_VERSION,
                optimizationUsed: optimizationUsed,
                runs: runs,
                constructorArguements: constructor_argument
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            const { result, message } = response.data;
            if (message === 'NOTOK' && result.includes("Unable to locate ContractCode at")) {
                await new Promise(resolve => setTimeout(resolve, 5000));

                if (totalTry <= 5) {
                    verifyContract(data, type, subtype, chain_id, totalTry + 1)
                    return false;
                }
            }

            if (result && inserted_Id) {
                let formData = new FormData();
                formData.append("id", inserted_Id);
                formData.append("verify_id", result);
                formData.append("request_type", 'update_verify_id');
                await api.post(`/`, formData)
            }

            return result;

        } catch (error) {
            console.error('Error verifying contract:', error);
        }
    }


    const opts = {
        width: '100%',
        height: "400px"
    };

    return (
        <div className='row border-dark bg-body-card mx-0 py-3 d-flex align-items-center text-center justify-content-center'>
            <div className='col-12 col-md-4 col-xl-3 mb-4 mb-xl-0'>
                <span>Service Fess : <span className='text-warning'>{
                    createType === 0 ? stats.basicFees :
                        createType === 1 ? stats.standardFees :
                            createType === 2 ? stats.premiumFees : ' - '
                } {chain && contract[chain.id] ? contract[chain.id].symbol : contract[DEFAULT_CHAIN].symbol}</span></span>
            </div>
            <div className='col-12 col-md-5 col-xl-5 text-center flex-column py-2 bg-card' style={{ borderRadius: "40px" }}>
                <h5 className='mb-0 mt-2'>Watch video tutorial</h5>
                <button id="btnModal" type="button" class="btn text-danger mb-0 mt-0" style={{ "backgroundColor": "transparent" }} onClick={handleShow}>
                    <img src={youtubeImg} alt="youtube" height="45px" width="45px" />
                </button>
                <p className='mb-2'>Time 1 Mintue</p>
            </div>
            {address ? (
                <div className='col-12 col-md-2 col-xl-3 mb-4 mt-4 mt-xl-0 mb-xl-0'>
                    <button disabled={loading} class="btn btn-ms mb-0 me-1 text-dark bg-warning" onClick={() => handleSubmit()}>
                        {loading ? 'Loading...' : 'Submit'}
                    </button>
                </div>
            ) : (
                <div className='col-12 col-md-3 mb-4 mt-4 mt-md-0 mb-md-0'>
                    <Connect />
                </div>
            )}


            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}>
                <Modal.Body>
                    <YouTube opts={opts} videoId={videoId} />
                </Modal.Body>

            </Modal>
        </div>
    )
}
